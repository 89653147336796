/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import List from '../../components/List';
import LoadingIndicator from '../../components/LoadingIndicator';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import FyrkList from '../../components/FyrkList';
import BreadCrumb from '../../components/BreadCrumb';
import withStyles from '@material-ui/styles/withStyles';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import * as Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@material-ui/core/Typography';
import _, { create } from 'lodash';

import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { useMatomo } from "@datapunt/matomo-tracker-react";


import FyrkLogo from '../../images/fyrk_logo.svg';

import {
  makeSelectCurrentUser,
  getFyrk,
  makeSelectLoading,
  makeSelectError,
  makeSelectValue,
  getLocationName,
  makeSelectCategoriesMainSelected,
  makeSelectCategoriesMainSelectedNames,
  makeSelectUserId,
  getFavoriter,
  makeSelectCategoriesMain,
  makeSelectCategories,
  makeSelectSearchFieldValue,
  makeSelectSearch,
  makeSelectSuggestions,
  getHelp
} from '../../containers/App/selectors';


import {
  makeSelectListView,
  makeSelectMasonryView,
  getPage,
  getMore,
  getExpanded,
  getSelectedPeriodType
} from './selectors';

import CenteredSection from './CenteredSection';
import Section from './Section';
import {  
  handleSearch,
  setSuggestions,
  clearSuggestions,
  handleSearchFieldValue,
  loadFyrk,
  removeCategory,
  handleChangePlats,
  addCategory,
   } from '../../containers/App/actions';

import { toListView, toMasonryView, changePage, setExpanded, setPeriodType } from './actions';

import reducer from './reducer';
import saga from './saga';
const styles = theme => ({
  alignright: {
    float: 'right',
    marginRight: '1em',
    textAlign: 'right',
  },
  alignleft: {
    marginLeftt: '1em',
    textAlign: 'left',
    float: 'left',
  },
  nomarginSection: {
    margin: "0 !important",
    paddingBottom: '2em',
  },
logo: {
  padding: '3em 0 2em 0',
},
  info: {
    padding: '0.5em',
    position: 'relative',
    marginLeft: '1em',
    top: '-2em',
  },
  textField: {
    margin: `${1}em`,
    width: `${90}%`,
  },
  suggestion: {
    display: 'block',
    width: '100%',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: '0.2em',
    paddingBottom: '0.2em',
  },
  listItemNormal: {
    marginLeft: '0.5em',
    color: '#BDBDBD',
  },
  listItemSelected: {
    marginLeft: '0.5em',
    color: '#f57d6c !important',
  },
  chip : {
  '&:hover': {
    backgroundColor : "#44AB66",
    color: "#EBEBEB"
  },
  '&:focus': {
    backgroundColor : "#EBEBEB",
    color: "#44AB66"
  },
  color: "#44AB66",
  fontWeight: "600",
  backgroundColor: '#EBEBEB',
  margin: "4px 4px",
  verticalAlign: 'middle',
  cursor: 'pointer',
},
periodchip : {
  '&:hover': {
    backgroundColor : "#EBEBEB",
    color: "#44AB66"
  },
  '&:focus': {
    backgroundColor : "#44AB66",
    color: "#FFFFFF"
  },
  color: "#FFFFFF",
  fontWeight: "400",
  backgroundColor: '#44AB66',
  margin: "4px 4px",
  verticalAlign: 'middle',
  cursor: 'pointer',
},
selectedChipLabel : {
   color: "#44AB66",
   fontWeight: "600",
},
selectitem : {
  color: "#44AB66",
  fontWeight: "600",
},
selectedChip : {
  backgroundColor : "#E2F3E7",
  '&:hover': {
    backgroundColor : "#E2F3E7"
  },
  '&:focus': {
    backgroundColor : "#E2F3E7"
  }
},
textFieldSearch: {
  background : "#fff",
  border: 'none',
  '&:before': {
    border: 'none',
  },
  paddingLeft: "16px",
  borderRadius: "16px",
  boxShadow: "0px 1px #ddd",
},
});
export class Fyrk extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  /**
   * when initial state username is not null, submit the form to load repos
   */
  componentDidMount() {
    this.props.loadFyrk();
  }

  render() {
    const {
      loading,
      error,
      fyrk,
      classes,
      userid,
      page,
      user,
      listview,
      masonryview,
      favoriter,
      more,
      expanded,
      selectedmaincategories,
      searchfieldvalue,
      selectedperiodtype,
      breadcrumb,
      locationname,
      location,
      helptexts
    } = this.props;
    let suggestions = [];
    const breadcrumbProps = { breadcrumb, locationname ,location};
    const reposListProps = {
      listview,
      masonryview,
      loading,
      error,
      fyrk,
      userid,
      user,
      favoriter,
    };

    let loader = '';
    if (loading) {
      loader = <List component={LoadingIndicator} />;
    }

    let fyrk_page_info = "";
    if (helptexts) {
      const temptext = _.find(helptexts, { slug: 'fyrk_page_info' });
      if (temptext) {
        fyrk_page_info = temptext.description;
      }
    }
    const nextpage = page + 1;
    let morebutton = '';
    if (more) {
      morebutton = (
        <Button
          className={classes.morebutton}
          onClick={evt => {
            this.props.onMore(nextpage);
          }}
        >
          {' '}
          MER{' '}
        </Button>
      );
    }

    function renderInput(inputProps) {
      const { classes, ref, ...other } = inputProps;

      return (
        <TextField
          className={classes.textField}
          InputProps={{
            inputRef: ref,
            ...other,
          }}
        />
      );
    }


    const fyrkcategories = [
      {id : 147, name : "Barnkultur"},
      {id : 149, name : "Bildkonst"},
      {id : 172, name : "Forskning"},
      {id : 173, name : "Fortbildning"},
      {id : 193, name : "Information"},
      {id : 203, name : "Konst"},
      {id : 207, name : "Konstnärsresidens"},
      {id : 210, name : "Kultur"},
      {id : 215, name : "Litteratur"},
      {id : 271, name : "Utbildning"},
      ];


    const fyrkcategorylist = fyrkcategories.map(list => {
        if (!selectedmaincategories.includes(list.id)) {
          return (
            <Chip

              className={classes.chip}
              key={list.id}
              label={list.name}
              onClick={() => {
                this.props.addCategory(list.id, list.name);
              }}
            />

          );
        }
        return (
          <Chip
            key={list.id}
            classes={{root: classes.selectedChip, label: classes.selectedChipLabel, icon: classes.selectedChipLabel }}
            icon={<DoneIcon />} label={list.name}
            onClick={() => {
              this.props.removeCategory(list.id);
            }}
          />
        );

    });
    const periodtypes = [
      {id : "VARIABLE,EXACT", name : "Exakt"},
      {id : "CONTINUOUS", name : "Fortlöpande"}
      ];
    const periodtype = periodtypes.map(list => {
        if (selectedperiodtype !== list.id) {
          return (
            <Chip
              className={classes.periodchip}
              key={list.id}
              label={list.name}
              onClick={() => {
                this.props.setPeriodType(list.id);
              }}
            />

          );
        }
        return (
          <Chip
            key={list.id}
            classes={{root: classes.selectedChip, label: classes.selectedChipLabel, icon: classes.selectedChipLabel }}
            icon={<DoneIcon />} label={list.name}
            onClick={() => {
              this.props.setPeriodType("");
            }}
          />
        );

    });

    const getSuggestionValue = suggestion => suggestion.name;
    const renderSuggestion = suggestion => (
      <div>
        {suggestion.name}
      </div>
    );


    let searchFilters = (
        <div>
          <Autosuggest
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderInputComponent={renderInput}
            suggestions={suggestions}
            onSuggestionsFetchRequested={
              this.props.handleSuggestionsFetchRequested
            }
            onSuggestionsClearRequested={
              this.props.handleSuggestionsClearRequested
            }
            onSuggestionSelected={this.props.handleSuggestionSelected}
            //renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
              classes,
              className: classes.textFieldSearch,
              disableUnderline: true,
              placeholder: 'Sök bidragskälla eller kategori',
              value: searchfieldvalue,
              onChange: this.props.handleChangeAutoComplete,
              onKeyPress: this.props.handleAutoSearch,
            }}
          />
          </div>
      );

    return (
      <article>
        <Helmet>
          <title>Fyrk.fi</title>
          <meta name="Fyrk.fi" content="" />
        </Helmet>
        <CenteredSection>
          <Grid container style={{ marginTop: '3em !important' }}>
          <Grid item md={4} />
          <Grid item sx={12} md={4} className={classes.logo}>
          <img className={classes.logo} src={FyrkLogo} alt="logo"/>
          <div>
          <Button
          onClick={() => this.props.setExpanded(!expanded)}>
          INFO <PlayArrowIcon /></Button>
          </div>
          {searchFilters}
          <p/>
          <Typography variant="subtitle2" gutterBottom>
            Kategorier
          </Typography>
          <p/>
          {fyrkcategorylist}
          <p/>
          <Typography variant="subtitle2" gutterBottom>
          Ansökningstider
          <p/>
          </Typography>
          {periodtype}
          </Grid>
          <Grid item md={4} />
          </Grid>
          <Grid container>
          <Grid item xs={1} />
          <Grid item xs={4} md={7} className={classes.alignleft}>
          {/* <Button
          onClick={() => this.props.setExpanded(!expanded)}>
          INFO <PlayArrowIcon /></Button> */}
          <BreadCrumb {...breadcrumbProps} 
              removeCategory={(id) => {
                this.props.removeCategory(id);
              }} 
              removeAllCategories={() => {
                this.props.removeCategoryAll();
              }}/>
          </Grid>

          <Grid item xs={4} md={2} className={classes.alignright}>
            <IconButton
              onClick={() => {
                this.props.onChangeViewMasonry();
              }}
            >
              <ViewModule />
            </IconButton>
            <IconButton
              onClick={() => {
                this.props.onChangeViewList();
              }}
            >
              {' '}
              <ViewList />{' '}
            </IconButton>
            </Grid>
            </Grid>
        </CenteredSection>
        <Dialog
        open={this.props.expanded}
        onClick={() => this.props.setExpanded(!expanded)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Card style={{padding:'20px'}}>
        {fyrk_page_info}
      
          </Card>
        </Dialog>
        <Section>
          <FyrkList {...reposListProps} />
        </Section>

        {loader}
        <CenteredSection>{morebutton}</CenteredSection>
      </article>
    );
  }
}

Fyrk.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  fyrk: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  loadFyrk: PropTypes.func,
  username: PropTypes.string,
  onChangeUsername: PropTypes.func,
  addCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  handleChangeAutoComplete: PropTypes.func,
  getSelectedPeriodType: PropTypes.func
};

export function mapDispatchToProps(dispatch) {
  return {
    addCategory: (id, name) => {
      dispatch(addCategory(id, name));
      dispatch(loadFyrk());

    },
    removeCategory: id => {
      dispatch(removeCategory(id));
      dispatch(loadFyrk(true));
    },
    removeCategoryAll: () => {
      dispatch(removeCategory('all'));
      dispatch(loadFyrk(true));
    },
    
    loadFyrk: () => {
      dispatch(loadFyrk());
    },
    onChangeViewList: evt => dispatch(toListView()),
    onChangeViewMasonry: evt => dispatch(toMasonryView()),
    onMore: page => {
      dispatch(changePage(page));
      dispatch(loadFyrk(true));
    },
    setExpanded: value => {
      dispatch(setExpanded(value))
    },
    setPeriodType: id => {
      dispatch(setPeriodType(id));
      dispatch(loadFyrk(true));
    },
    removeLocation: id => {
      dispatch(handleChangePlats());
      dispatch(loadFyrk(true));
    },
    handleSuggestionsFetchRequested: value => {
      dispatch(setSuggestions(value));
    },
       handleSuggestionsClearRequested: () => {
      dispatch(clearSuggestions(''));
    },
    handleChangeAutoComplete: (event, value) => {
      dispatch(handleSearchFieldValue(event.target.value));
    },
    handleAutoSearch: event => {
      if (event.key === 'Enter') {
        dispatch(removeCategory('all'));
        dispatch(handleSearch(event.target.value));
        // dispatch(addCategory(newValue.suggestionValue.id));
        dispatch(loadFyrk());
      }
    },
  };
}

//const [expanded, setExpanded] = React.useState(false);

const mapStateToProps = createStructuredSelector({
  fyrk: getFyrk(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  value: makeSelectValue(),
  breadcrumb: makeSelectCategoriesMainSelectedNames(),
  userid: makeSelectUserId(),
  user: makeSelectCurrentUser(),
  listview: makeSelectListView(),
  masonryview: makeSelectMasonryView(),
  page: getPage(),
  favoriter: getFavoriter(),
  more: getMore(),
  locationname: getLocationName(),
  expanded: getExpanded(),
  suggestions: makeSelectSuggestions(),
  selectedperiodtype : getSelectedPeriodType(),
  searchfieldvalue: makeSelectSearchFieldValue(),
  search: makeSelectSearch(),
  categories: makeSelectCategories(),
  selectedmaincategories: makeSelectCategoriesMainSelected(),
  maincategories: makeSelectCategoriesMain(),
  helptexts: getHelp(),


});


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'fyrk', reducer });
const withSaga = injectSaga({ key: 'fyrk', saga });

const FyrkWithMatomo = (props) => {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView({});
  }, []);

  return <Fyrk {...props} />;
};

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withStyles(styles),
)(FyrkWithMatomo);
